import {useLocation, useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useEffect, useRef, useState} from 'react';
import {signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import SnackbarWithCustomBackground from "./snackbar";
import LazyLoad from 'react-lazyload';
import playIc from "../assets/playSvg.svg";


import {
    FaDailymotion, FaEnvelope,
    FaFacebook, FaFacebookMessenger, FaGlobe,
    FaInstagram,
    FaPaperclip, FaPinterest,
    FaSearch,
    FaShareAlt, FaSnapchat, FaStripe, FaThumbtack,
    FaTiktok, FaTimes, FaWhatsapp, FaYoutube,
} from "react-icons/fa";
import {FaPaypal} from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import {
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    InputAdornment,
    MenuItem, Skeleton,
    TextField, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import ShareDrawer from "./sharedrawer";
import imglogo from "../assets/mdi_images.png";
import rec from "../assets/rec.png";
import icLydia from "../assets/iconLydiaWhite.svg";
import icPaylib from "../assets/iconPaylibWhite.svg";
import icWise from "../assets/iconWiseWhite.svg";
import icRevolut from "../assets/iconRevolutWhite.svg";
import icLinktree from "../assets/iconLinktreeWhite.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinksWhite.svg";
import icSumUp from "../assets/iconSumUpWhite.svg";
import deleteic from "../assets/icdeletetag.svg";
import {firebaseAuth, loadHome} from "../firebase";
import {removeFromLocalStorage, saveToLocalStorage} from "./utils";
import {CheckDepositButton} from "./continueDepositButton";

const faImageStyle = {
    position: 'absolute !important',
    top: '5px !important',
    right: '5px !important',
    opacity:'100%',
    color: '#F2F2F2',
    width: '30px',
    height: '30px',
    zIndex: 1,
};

const faStatusStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    opacity:'100%',
    zIndex: 1,
};

const faPinStyle = {
    position: 'absolute',
    top: '8px',
    left: '5px',
    opacity:'100%',
    zIndex: 1,
};

export default function Home() {

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [userObject, setUserObject] = useState({});
    const [prestaArray, setPrestaArray] = useState([]);
    const [displayPage, setDisplayPage] = useState(null);
    const { pathname } = useLocation();
    const [uid, setUid] = useState("");
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let count =0;
    useEffect(() => {

        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            //if (!(user && user.isAnonymous)) {
            if (!(user && user.isAnonymous)) {
                try {
                    count++;
                    if (count<4){
                        await signInAnonymously(firebaseAuth);
                    }
                } catch (e) {
                    setDisplayPage(false);
                }
            }
            if (user){


                const fetchData = async () => {
                    try {
                        const relativeURL = location.pathname;
                        if (relativeURL==="/"){
                            window.location.href = 'https://faismoibg.com';
                        }
                        const key = relativeURL.replace(/\//g, '');
                        setUid(key);
                        const response =  await loadHome({id:key});
                        setData(response?.data);
                        if (response?.data?.success){
                            //display page
                            setUserObject(response.data.userData);
                            setPrestaArray(response.data.prestaData);
                            saveToLocalStorage("userObject", response.data.userData);
                            setDisplayPage(true);
                        } else {
                            setDisplayPage(false);
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                await fetchData();
            }
        });


        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    //snackbar logic

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [deployTags, setDeployTags] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [filterTags, setFilterTags] = useState([]);
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const dialRef = useRef(null);
    const [subStatus, setSubStatus] = useState("");

    useEffect(() => {
        if (openTagDialog && dialRef.current) {
            dialRef.current.focus();
        }
    }, [openTagDialog]);

    useEffect(() => {
        // Clear the localStorage data when the component mounts
        localStorage.removeItem('currentState');
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    //Navigation
    const navigateToRdv = () => {
        navigate("/rdv");
    };

    const handlePrestaNavigation = (documentId) => {
        navigate("details/"+documentId);
    };

    const toggleDrawer = async () => {
        //setDrawerIsOpen((prev) => !prev);

        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Partager une page faismoibg',
                    text: 'Voici le lien vers la page faismoibg de ' + userObject.pseudo,
                    url: 'https://faismoibg.com/' + userObject.urlPerso,
                });
            } else {
                setDrawerIsOpen((prev) => !prev);
            }
        } catch (error) {
            console.error('Error sharing content:', error);
        }
    };

    const handleSocialClick = (link) => () => {
        window.open(link, '_blank');
    };


    const handleMenuToggle = (event) => {
        event.stopPropagation();
        setDeployTags(!deployTags); // Close the menu by setting anchorEl to null
    };

    const divRef = useRef(null); // Create a ref for your div element

    useEffect(() => {
        function handleClickOutside(event) {
            if (divRef.current && !divRef.current.contains(event.target)) {
                // Click occurred outside of the div, so you can trigger your close function here
                // For example, you can set deployTags to false to close the div
                setDeployTags(false);
            }
        }

        // Attach the event listener when deployTags is true
        if (deployTags) {
            document.addEventListener('click', handleClickOutside);
        } else {
            // Clean up the event listener when deployTags is false to avoid memory leaks
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, [deployTags]);

    const handleTempSelection = (keyword) => {
        setSelectedTags((prevSelectedTags) => {
            // Check if the keyword is already in selectedTags
            const isTagSelected = prevSelectedTags.includes(keyword);
            // If the tag is already selected, remove it; otherwise, add it
            if (isTagSelected) {
                return prevSelectedTags.filter(tag => tag !== keyword);
            } else {
                return [...prevSelectedTags, keyword];
            }
        });
    };

    const handleValidateSelection = () => {
        setFilterTags(selectedTags);
        setDeployTags(false);
    };

    const handleTagDelete = (tag) => {
        const newFilterTags = filterTags.filter(item => item !== tag);
        const newSelectedTags = selectedTags.filter(item => item !== tag);
        setFilterTags(newFilterTags);
        setSelectedTags(newSelectedTags);
    };

    const handleOpenTagsDialog = () => {
        setOpenTagDialog(true);
    };

    const handleTagDialogCloseOutside = (event) => {
        setOpenTagDialog(false);
    };

    const handleTagDialogClose = (event) => {
        setFilterTags(selectedTags);
        setDeployTags(false);
        setOpenTagDialog(false);
    };

    return (
        <div className="sub-container">
            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"25px"}} variant="circular" width={110} height={110} />
                        <Skeleton style={{borderRadius:"5px", marginTop:"20px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                        <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="60%" height={60}/>
                        <Skeleton style={{marginTop:"35px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top">
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                margin: 'auto',
                                marginTop: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor:'#F1F1F1',
                                position:"relative"
                            }}
                        >
                            {userObject.imageUrl ? (
                                <img onLoad={handleImageLoad}
                                     className="fade-in"
                                     src={userObject.imageUrl} alt="Uploaded" style={{
                                    borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', minWidth:"100px" }} />
                            ) : (
                                <img src={rec} style={{ borderRadius: '50%', width: '110px', height: '110px', objectFit: 'cover' }} ></img>
                            )}
                            {/*<div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1' }}>
                        <IconButton className="button-style-borders-icon" variant="outlined"
                                    onClick={navigateToSettings} style={{ zIndex: '1', borderRadius: '50%', padding: '10px'
                            , transform: 'translate(28%, -15%)', backgroundColor:"white" }}>
                            <img src={iconSettings} style={{width:"17px", height:"17px"}}/>
                        </IconButton>
                    </div>*/}
                        </div>
                        <div style={{width:"80%", marginTop:"20px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <span style={{fontFamily:"Nunito Black", color:"black", fontSize:"20px", fontWeight:"900", lineHeight:"24px"}}>{userObject.pseudo || ""}</span>
                        </div>
                        <div style={{width:"90%",maxWidth:"90%", marginTop:"20px", display:"flex"}}>
                    <span style={{width:"100%", color:"black", fontSize:"16px",
                        fontWeight:"400", textAlign:"center", lineHeight:"22px",
                        whiteSpace:"pre-line",maxWidth: "100%", overflowWrap:"break-word"}}>{userObject.presentation || ""}</span>
                        </div>
                        <div style={{ width: "100%", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap:"wrap" }}>
                                {userObject?.socialLinks?.map((socialLink, index) => {
                                    if (socialLink?.link?.length > 0) {
                                        const iconComponent = {
                                            facebook: <FaFacebook style={{ fontSize: '1.25rem', color: "white" }} />,
                                            instagram: <FaInstagram style={{ fontSize: '1.25rem', color: "white" }} />,
                                            tiktok: <FaTiktok style={{ fontSize: '1.25rem', color: "white" }} />,
                                            messenger: <FaFacebookMessenger style={{ fontSize: '1.25rem', color: "white" }} />,
                                            pinterest: <FaPinterest style={{ fontSize: '1.25rem', color: "white" }} />,
                                            snapchat: <FaSnapchat style={{ fontSize: '1.25rem', color: "white" }} />,
                                            whatsapp: <FaWhatsapp style={{ fontSize: '1.25rem', color: "white" }} />,
                                            paypal: <FaPaypal style={{ fontSize: '1.25rem', color: "white" }} />,
                                            lydia: <img src={icLydia} style={{ color: "white", width:"20px", height:"20px" }} />,
                                            paylib: <img src={icPaylib} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover",scale:"1.2" }} />,
                                            stripe: <FaStripe style={{ fontSize: '1.25rem', color: "white", scale:"1.3"  }} />,
                                            sumup: <img src={icSumUp}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover"}} />,
                                            wise: <img src={icWise} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.8" }} />,
                                            revolut: <img src={icRevolut} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                            linktree: <img src={icLinktree} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover" }} />,
                                            linkinbio: <FaPaperclip style={{ fontSize: '1.25rem', color: "white" }} />,
                                            beacons: <img src={icBeacons} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                            allmylinks: <img src={icAllMyLinks}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                            youtube: <FaYoutube style={{ fontSize: '1.25rem', color: "white" }} />,
                                            dailymotion: <FaDailymotion style={{ fontSize: '1.25rem', color: "white" }} />,
                                            email: <FaEnvelope style={{ fontSize: '1.25rem', color: "white" }} />,
                                            site: <FaGlobe style={{ fontSize: '1.25rem', color: "white" }} />,
                                        }[socialLink?.network];

                                        return (
                                            <a href={socialLink.link} target="_blank" key={index}>
                                                <IconButton className="button-style-noborders-socialicon" style={{ padding: "10px", marginLeft: "5px", marginRight: "5px", marginTop:"10px" }}>
                                                    {iconComponent}
                                                </IconButton>
                                            </a>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        {/*
                <div className="button-container" style={{marginTop:"50px"}}>
                    <Button className="button-style-noborders-home" variant="contained" onClick={navigateToPreferences}
                            style={{borderRadius: "15px",
                                background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                boxShadow: "0px 3px 10px 0px rgba(72, 8, 255, 0.25)"}}
                            startIcon={<img src={rectlistlogo} className="logo-size" style={{ color:'#FFFFFF', marginRight:"8px", paddingTop:"2px"}}/>} >
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontSize:"20px", fontWeight:"700" }}>
                            Modifier votre profil
                        </Typography>
                    </Button>
                </div>
                <div className="button-container" style={{marginTop:"20px"}}>
                    <Button className="button-style-noborders-home" variant="contained" onClick={navigateToNewPresta}
                            style={{borderRadius: "15px",
                                background: "linear-gradient(90deg, #4808FF 0%, #6834FA 100%)",
                                boxShadow: "0px 3px 10px 0px rgba(72, 8, 255, 0.25)"}}
                            startIcon={<img src={paintbrushlogo} className="logo-size" style={{ color:'#FFFFFF', marginRight:"8px", paddingTop:"2px"}}/>} >
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontSize:"20px", fontWeight:"700" }}>
                            Créer une prestation
                        </Typography>
                    </Button>
                </div>
                <div className="button-container" style={{marginTop:"20px"}}>
                    <Button className="button-style-noborders-home" variant="contained" onClick={navigateToRdv}
                            style={{borderRadius: "15px",
                                background: "linear-gradient(90deg, #FA3464 0%, #9747FF 100%)",
                                boxShadow: "0px 3px 10px 0px rgba(72, 8, 255, 0.25)"}}
                            startIcon={<img src={calendarpluslogo} className="logo-size" style={{ color:'#FFFFFF', marginRight:"8px", paddingTop:"2px"}}/>} >
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontSize:"20px", fontWeight:"700" }}>
                            Vos rendez-vous
                        </Typography>
                    </Button>
                </div>
                <div className="button-container" style={{marginTop:"20px"}}>
                    <Button className="button-style-noborders-home" variant="contained" onClick={navigateToSub}
                            style={{borderRadius: "15px",
                                background: "linear-gradient(90deg, #FA3464 0%, #FA3464 100%)",
                                boxShadow: "0px 3px 10px 0px rgba(72, 8, 255, 0.25)"}}
                            startIcon={<img src={creditcardlogo} className="logo-size" style={{ color:'#FFFFFF', marginRight:"8px", paddingTop:"2px"}}/>} >
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF', fontSize:"20px", fontWeight:"700"}}>
                            Votre abonnement
                        </Typography>
                    </Button>
                </div>*/}

                        <div className="field-container" style={{marginTop:"50px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                            <div className="title-field-container" style={{width:"70%"}}>
                                  <span className="text-field-style">
                                      Choisir une prestation
                                  </span>
                            </div>
                            <div style={{width:"30%", justifyContent:"end", display:"flex"}}>
                                <Chip onClick={handleOpenTagsDialog}
                                      style={{ marginBottom:"10px",
                                          background:"black", cursor:"pointer",
                                          color:"white", fontWeight:"400", justifySelf:"end", padding:"5px 10px", fontSize:"14px"}} label="Filtrer">
                                </Chip>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                            <TextField style={{width:"100%"}} className="field-style" id="outlined-basic" label="Rechercher" variant="outlined" value={searchQuery}
                                       onChange={(e) => setSearchQuery(e.target.value)}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <FaSearch style={{color:"black"}} />
                                               </InputAdornment>
                                           ),
                                       }}/>
                            {/*<div style={{display:"flex", width:"20%", justifyContent:"center"}}>
                        <IconButton
                            onClick={handleMenuToggle}
                            className="button-style-no-borders" variant="outlined"
                                    style={{ zIndex: '1', borderRadius: '50%', padding: '10px'
                            , placeSelf:"center", justifySelf:"center", width:"50px", height:"50px", backgroundColor:"white" }}>
                            <img src={icFilter} style={{
                                transform: deployTags ?  'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                                width:"24px", height:"24px"}}/>
                        </IconButton>
                    </div>*/}

                        </div>


                        {/*JustifyContent end in case you enable the "recent" option*/}
                        <div style={{width:"100%", marginTop:"10px", display:"flex", justifyContent:"start"}}>
                            <div style={{display:"flex", width:"80%", flexDirection:"column"}}>

                                <div style={{display:"flex", maxWidth:"95%", flexWrap:"wrap"}}>
                                    {filterTags.map((tag, index)=>(
                                        <Chip
                                            onDelete={() => handleTagDelete(tag)}
                                            key={index}
                                            deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                            style={{
                                                background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", margin:"5px",
                                                color:"white", fontWeight:"400", placeSelf:"end", lineHeight:"20px"}}
                                            label={tag}>
                                        </Chip>
                                    ))}
                                </div>

                            </div>

                            {/*<Chip onClick={() => setSortAscending(!sortAscending)}
                        style={{width:"20%", marginTop:"5px",
                            background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", cursor:"pointer", color:"white", fontWeight:"400", justifySelf:"end"}} label={sortAscending ? "Ancien" : "Récent"}></Chip>*/}
                        </div>
                        <div className="image-gallery-container" style={{marginTop:"30px"}}>
                            {prestaArray.filter((doc) => {
                                // Check if filterTags is empty or if doc.keywords contains any of the tags in filterTags
                                return (
                                    filterTags.length === 0 ||
                                    filterTags.some((tag) =>
                                        doc.keywords.some((keyword) => keyword.includes(tag))
                                    )
                                );
                            }).filter((doc)=>
                                searchQuery.trim() === ''
                                    ? true // Return all results if searchQuery is empty
                                    : doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                    doc.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                    doc.price.toString().includes(searchQuery.trim())
                            ) .sort((a, b) => {
                                if (a.isPinned && !b.isPinned) {
                                    return -1; // 'a' comes first because it is pinned
                                } else if (!a.isPinned && b.isPinned) {
                                    return 1; // 'b' comes first because it is pinned
                                } else {
                                    if (sortAscending) {
                                        return new Date(a.timestamp?._seconds * 1000) - new Date(b.timestamp?._seconds * 1000); // Sort in ascending order
                                    } else {
                                        return new Date(b.timestamp?._seconds * 1000) - new Date(a.timestamp?._seconds * 1000); // Sort in descending order
                                    }
                                }
                            }) .map((doc, index) => (
                                <div key={index} className="image-container-home"
                                     onClick={() => handlePrestaNavigation(doc.documentId)} >
                                    <div className="image-wrapper-home">
                                        {doc.imageLinks.some(item => item.vlink.length > 0) ? (
                                            // Render a different image when the condition is met
                                            <div className="custom">
                                                <img src={playIc} style={{ borderRadius: "none" }} />
                                            </div>
                                        ) : (
                                            // Render the default image when the condition is not met
                                            <div className="custom">
                                                <img src={imglogo} style={{ borderRadius: "none" }} />
                                            </div>
                                        )}

                                        {doc.isPinned && (
                                            <div style={faPinStyle}>
                                                <FaThumbtack style={{color:"white", width:"20px", height:"20px"}}></FaThumbtack>
                                            </div>
                                        )}

                                        {/* Image */}
                                        {doc.imageLinks && doc.imageLinks.length > 0 && doc.imageLinks[0].imageLink.length > 0 ? (
                                            <LazyLoad>
                                                <img onLoad={handleImageLoad}
                                                     className="fade-in imstyle" src={doc.imageLinks[0].imageLink} alt={`Image ${index + 1}`} />
                                            </LazyLoad>
                                        ) : (
                                            <LazyLoad>
                                                <img onLoad={handleImageLoad}
                                                     className="fade-in imstyle" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." alt={`Image ${index + 1}`} />
                                            </LazyLoad>
                                        )}
                                    </div>

                                    {/* Text below the image */}
                                    <div className="image-text-home">
                                        <span>{doc.title}</span>
                                    </div>
                                </div>
                            ))}
                            {prestaArray.length===0 && (
                                <div style={{display: "flex", width:"100%", padding: "20px", flexDirection: "column", justifyContent: "start", marginTop:"30px",
                                    alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                    <span style={{lineHeight: "24px"}}>
                                        Aucune prestation
                                    </span>
                                </div>
                            )}

                        </div>


                        <div className="button-container" style={{marginTop:"80px"}}>
                            <Button style={{marginBottom:"100px"}} onClick={toggleDrawer} startIcon={<FaShareAlt style={{color:"white"}}></FaShareAlt>} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{  marginLeft:"8px", textTransform: 'none', color:'#FFFFFF' }}>
                                    Partagez ce beauty artist
                                </Typography>
                            </Button>
                        </div>

                        <div style={{display:"flex"}}>
                            <ShareDrawer urlPerso={userObject.urlPerso} isOpen={drawerIsOpen} onClose={toggleDrawer} />
                        </div>

                        <Dialog ref={dialRef} open={openTagDialog} onClose={handleTagDialogCloseOutside}
                                fullWidth={!isLargeScreen}
                                PaperProps={{
                                    style: {
                                        minWidth: isLargeScreen ? '500px' : 'auto',
                                        maxWidth: isLargeScreen ? '500px' : 'auto',
                                    },
                                }}
                        >

                            <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                                <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Filtrer par catégorie</span>
                            </DialogTitle>
                            <DialogContent style={{backgroundColor:"white",maxHeight:"600px", overflowY:"auto"}}>
                                <div style={{display:"flex", width:"100%", flex: 1,
                                    flexDirection:"column", paddingTop:"20px", paddingBottom:"20px"}}>
                                    {Array.from(new Set(prestaArray.flatMap(doc => doc.keywords)))
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((keyword, index, array) => (
                                        <MenuItem onClick={()=> (handleTempSelection(keyword))}
                                                  style={{
                                                      zIndex:1000,
                                                      background:selectedTags.includes(keyword)
                                                          ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                          : "#DAE0E5",
                                                      marginBottom: "10px",
                                                      padding: "10px 15px",
                                                      borderRadius:"15px"
                                                  }}
                                                  key={index}
                                                  value={keyword}
                                        >
                                                            <span style={{width: "100%",
                                                                overflow: "hidden",
                                                                color:selectedTags.includes(keyword)
                                                                    ? "white"
                                                                    : "black",
                                                                fontWeight:selectedTags.includes(keyword)
                                                                    ? 700
                                                                    : 400,
                                                                fontSize:"16px",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"}}>
                                                                {keyword}
                                                            </span>
                                        </MenuItem>
                                    ))
                                    }


                                    {/*<div style={{display: "flex", width:"90%", flexDirection: "column", justifyContent: "start", marginTop:"10px", alignSelf:"center",
                                    alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                    <span style={{lineHeight: "24px", padding:"20px"}}>
                                        Vous n'avez pas encore ajouté de tag à vos prestations
                                    </span>
                                </div>*/}
                                    {prestaArray.every(item => item.keywords.length === 0) && (
                                        <div style={{
                                            display: "flex",
                                            width: "90%",
                                            alignSelf:"center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginTop: "20px"
                                        }}>
                                      <span style={{
                                          lineHeight: "24px",
                                          padding: "20px",
                                          borderRadius: "15px",
                                          background: "#FFF",
                                          boxShadow: '0px 4px 15px 0px rgba(218, 224, 229, 0.90)'
                                      }}>
                                        Ce beauty artist n'a pas encore ajouté de tag à ses prestations
                                      </span>
                                        </div>
                                    )}
                                </div>
                            </DialogContent>
                            <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                                <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                                    <Button onClick={handleTagDialogClose} className="button-style button-style-noborders" variant="contained">
                                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                            Enregistrer
                                        </Typography>
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas  🤷
                                        </span>
                        </div>
                    </div>
                )
            )}
        </div>
    )
}